<template>
	<div>
		<el-drawer title="盘点设备盘点结果预览" :visible.sync="endDrawer" class="end-discovery-drawer" ref="drawer" size="20%">
			<div class="range-box">
				<p style="font-size: 18px;font-weight: 600;color: black;">本次盘点范围</p>
				<div class="item">
					<span>公司部门</span>
					<span>{{info.range_dept_name?info.range_dept_name:'所有'}}</span> 
				</div>
				<div class="item">
					<span>资产分类</span>
					<span>{{info.range_cate_name?info.range_cate_name:'所有'}}</span>
				</div>
				<div class="item">
					<span>资产位置</span>
					<span>{{info.range_location_name?info.range_location_name:'所有'}}</span>
				</div>
			</div>
			<div class="calc-box">
				<p style="font-size: 18px;font-weight: 600;color: black;">本次盘点汇总</p>
				<el-row style="padding: 5px;">
					<el-col :span="8">
						<div class="grid-content bg-purple-light">
							盘点状态
						</div>
					</el-col>
					<el-col :span="8">
						<div class="grid-content bg-purple-light">
							数量
						</div>
					</el-col>
					<el-col :span="8">
						<div class="grid-content bg-purple-light">
							占比
						</div>
					</el-col>
					<el-col :span="8" v-for="data in info.calc_data">
						<div class="grid-content ">
							{{data}}
						</div>
					</el-col>
				</el-row>
			</div>
			<div style="text-align: center; width:100%;margin-top: 20px;">
				<el-button type="primary" plain style="width: 30%;" @click="endDrawer = false">取消</el-button>
				<el-button type="primary" style="width: 50%;" @click="submit()">提交盘点结果</el-button>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		inject:['reload'],
		data() {
			return {
				endDrawer:false,
				id:'',
				info:{}
			}
		},
		created() {

		},
		mounted() {

		},
		methods: {
			getData(id){
				this.id = id
				this.$api.get('AssetDiscovery/end',{
					id:id
				},res=>{
					if(res.code == 200){
						this.info = res.data.info
						
					}
				})
				this.endDrawer = true
			},
			submit(){
				this.$alert("盘点审批通过后会自动更新已盘资产更新的内容", "提交盘点结果", {
				    showConfirmButton: true,
				    showCancelButton: true,
				    callback: action => {
				        if (action == "confirm") {
				            this.$api.put("AssetDiscovery/end",{
								id:this.id
							},res=>{
				                if (res.code == 200) {
				                    this.$message.success(res.msg) 
				                    this.reload()
				                }else{
				                    this.$error(res.msg)
				                }
				            })
				        }
				    }
				});
			}
		},
	}
</script>
<style lang="scss">
	.end-discovery-drawer{
		.el-drawer__body{
			background:#F2F6FC;
			padding: 10px;
		}
	}
</style>
<style scoped lang="scss">
	.end-discovery-drawer{
		color:#666;
		font-size: 12px;
		.range-box,.calc-box{
			background: white;
			border-radius: 10px;
			text-indent: 20px;
			line-height: 30px;
		}
		.range-box{
			.item{
				display: flex;
				span{
					display: block;
				}
				:first-child{
					width: 25%;
				}
				:last-child{
					width: 65%;
					text-align: right;
				}
			}
			
		}
	}
	
</style>
