<template>
<div>
	<el-drawer 
		title="分配任务" 
		:visible.sync="assignDrawer" 
		custom-class="demo-drawer"
		 ref="drawer" 
		size="20%"
		:append-to-body="true"
		:modal-append-to-body="false"
		 :close-on-click-modal="false">
		<el-form  :model="assignForm" prop=""  style="margin-left:20px;" ref="assignForm">
			<template v-if="!batch_status">
				<el-form-item label="选择部门" prop="name">
					<el-cascader
						v-model="assignForm.selected_dept_id"  
						 :props="props" 
						:options="deptList" 
						  @change="handleChangeDept"
						  ref="cascader"
						></el-cascader>
				</el-form-item>
				<el-form-item label="人员姓名" prop=""> 
				   <el-select  v-model="assignForm.admin_id"   placeholder="请选择" @change="handleChangeSelect">
					 <el-option v-for="admin in adminList"  :value="admin.id" :label="admin.name"> </el-option>
				   </el-select>  
				</el-form-item>
				<div class="discovy-title-box"><span class="title">盘点范围</span><span class="tips">(不选范围则为发起人权限的全部资产)</span></div>
				<el-form-item label="使用部门"  prop="">
				   <el-select  v-model="assignForm.dept_id" multiple    placeholder="请选择" >
					 <el-option v-for="(dept,deptIndex) in deptList"   :value="dept.id" :label="dept.name"> </el-option>
				   </el-select> 
				</el-form-item>
				<el-form-item label="资产分类" prop="">
					   <el-select   ref="treeSelect"
						v-model="assignForm.cate_name" placeholder="请选择" clearable 
						>
							<el-option value="0" label="无上级">无上级</el-option>
							<el-tree   node-key="id" :data="cateTreeData" :props="treePorps"
								 show-checkbox
							 highlight-current ref="assignCateTree" 
							 
							 @check-change="handleAssignCheckTree('assignCateTree')"> </el-tree>
					   </el-select>
				</el-form-item>
				 <el-form-item label="资产位置" prop="">
				   <el-select   ref="treeSelect"
					v-model="assignForm.location_name" placeholder="请选择" clearable 
					>
						<el-option value="0" label="无上级">无上级</el-option>
						<el-tree   node-key="id" :data="locationTreeData" :props="treePorps"
						 show-checkbox
						 highlight-current ref="assignLocationTree" 
						 @check-change="handleAssignCheckTree('assignLocationTree')"> </el-tree>
				   </el-select>
				 </el-form-item>
			</template>
			<template v-else>
				  <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
				  <div style="margin: 15px 0;"></div>
				  <el-checkbox-group v-model="checkedAdmin" @change="handleCheckedAdminChange">
				    <el-checkbox v-for="admin in adminList" :label="admin.id" :key="admin.id">{{admin.name}}</el-checkbox>
				  </el-checkbox-group>
			</template>
		</el-form>
		<div style="text-align: center; width:100%;margin-top: 20px;">
			<el-button type="primary" style="width: 90%;margin: 0 auto;" @click="saveAssign()">保存</el-button>
		</div>
	</el-drawer>
</div>
</template>

<script>
export default {
	// props:['assignData'],
    data() {
        return {
			// active:0,
			// dialogAddVisible:false,
			assignDrawer:false,
			cateTreeData:[],
			locationTreeData:[],
			adminList:[],
			deptList:[],
			treePorps:{
				label:'name'
			},
			props:{
				value:'id',
				label:'name',
			},
			// assignTableData:this.assignData,
			assignTableData:[],
			batch_status:true,
			
			assignForm:{
				admin_id:'',
				name:'',
				cate_id:'',
				dept_id:'',
				cate_name:[],
				location_name:[],
				location_id:'',
				selected_dept_id:''
			},
			 checkAll: false,
			 checkedAdmin:[],
			isIndeterminate: true,
			checkedData:[]
        }
    },
    created() {
		console.log('onload')
    },
    mounted() {

    },
    methods:{
		   handleAssignCheckTree(name='assignCateTree'){
			  var temp = []
			  var keys  = ''
			  var selected_name = ''
			  if(name == 'assignCateTree'){
					keys  = 'cate_id'
					selected_name = 'cate_name'
			  }
			  if(name == 'assignLocationTree'){
					keys = 'location_id'
					selected_name = 'location_name'
			  } 
			  this.assignForm[keys] = this.$refs[name].getCheckedKeys()
			  let nodes =  this.$refs[name].getCheckedNodes()
			  nodes.map((item)=>{
				 temp.push(item.name)
			  })
			  this.assignForm[selected_name]  = temp 
		},
		handleChangeSelect(val){
			this.adminList.forEach((item)=>{
				if(item.id == val){
					this.assignForm.name =  item.name
				}
			})
		},

		//全选
	   handleCheckAllChange(val) {
		   let id = this.adminList.map((item)=>{ return item.id})
		   this.checkedAdmin = val?id:[]
		   if(val){
			   this.checkedData =  this.adminList
		   }
			this.isIndeterminate = false;
		},
		
		
	   handleCheckedAdminChange(value) {
			var t_data = []
			this.adminList.forEach((item)=>{
				value.forEach((v)=>{
					if(v == item.id){
						t_data.push(item)
					}
				})
			})
			this.checkedData = t_data
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.adminList.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.adminList.length;
	  },
	  
	  handleChangeDept(value){
		  let dept_id = value[value.length-1]
		  this.$api.get('admin/getDeptAdmin',{dept_id:dept_id},res=>{
		       if(res.code == 200){
		  			this.adminList =  res.data.list 
		       }else{
		           this.$message.error(res.msg)
		       }
		   })
	  },
	  saveAssign(){
		  if(this.batch_status){	//批量分配
			   this.tableData = []
			   this.checkedData.forEach((item,index)=>{
				    this.tableData[index]  = {
						admin_id:item.id,
						name:item.name,
						cate_name:'-',
						location_name:'-'
					};
			   })
			 this.checkedData = this.tableData
		  }else{	//分配任务
			if(!this.assignForm.admin_id){
				this.$message.error('请选择盘点人员')
				return false
			}
				if(this.assignTableData.length > 0){
					let i=0
					for(i;i<=this.assignTableData.length;i++){
						if(this.assignTableData[i].admin_id == this.assignForm.admin_id){
							  this.checkedData[i] = this.assignForm
						}else{
							  this.checkedData.push(this.assignForm)
						}
					}
				}else{
					 this.checkedData.push(this.assignForm)
				}		
		  }
		  this.$emit('update:assignData',this.checkedData) 
		  this.assignForm = {
				admin_id:'',
				name:'',
				cate_id:'',
				dept_id:'',
				cate_name:[],
				location_name:[],
				location_id:'',
			},
		 this.assignDrawer =  false

	  }
    },
}
</script>
<style scoped lang="scss">
	.discovy-title-box{
		width: 100%;
		height: 40px;
		line-height: 40px;
		.title{
			font-size: 18px;
			font-weight: 600;
			color: #303133;
			border-bottom: 2px solid #409EFF;
			margin-right: 5px;
		}
		.tips{
			font-size: 12px;
			color: #909399;
		}
	}
</style>