<template>
<div>
	<el-dialog title="执行盘点" :visible.sync='isShow' width="60%" :close-on-click-modal="false">
		<el-form class="asset-form" label-position="top"  v-if="formStatus"    ref="form" :model="formModel" prop="" >
			<template v-if="batchStatus">
				 <el-form-item   label="盘点照片" prop="">
					 <span style="color: #909399;">最大上传6张照片</span>
					 <el-upload
					 	class="avatar-uploader"
					 	:action="$uploadUrl"
					 	list-type="picture-card"
					 	 :file-list="fileList"
						 :limit="3"
					 	 :on-remove="handleRemove"
					 	:on-success="handleSuccessUploadImgs"
					 	:before-upload="handleBeforeUpload" >
					 	<img  v-if="form.value" :src="form.value" class="avatar">
					 	<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					 </el-upload> 
				 </el-form-item>
			</template>
			<template v-else>
			<el-tabs v-model="active">
				<el-tab-pane v-for="(item,index) in form" :label="item.group_name">
					<el-row>
						<div v-for="(form,formIndex) in item.data ">
							 <el-col :span="8"  v-if="form.type !==1  && form.type !== 4 ">
								 <el-form-item   :label="form.title" :prop="form.field_name">
									<!-- 单行文本 -->
									<el-input v-if="form.type< 1 && form.field_name == 'check_admin_id'"  
									:placeholder="form.title"
									v-model="form.check_admin_name"  
									:disabled="true"></el-input>
									 <el-input v-if="form.type< 1 &&  form.field_name !== 'check_admin_id'"   
									 :placeholder="form.title" 
										v-model="form.value" 
										:disabled="(type == 1 && form.field_name) 
										|| (type == 2 && form.field_name && form.field_name !== 'discovery_count' &&  form.field_name !== 'discovery_amount')?false:true"></el-input>
									 <!-- 所在位置 --> 
									 <el-select v-if="form.type == 9 && form.field_name == 'location_id'" 
										 ref="locationTreeSelect"  
										 v-model="form.location_name" 
										placeholder="请选择" clearable 
										:disabled="form.field_name?false:true">
										<el-option value="0" label="无上级">无上级</el-option>
										<el-tree   node-key="id" :data="locationTreeData" :props="treePorps" highlight-current ref="tree" 
											@node-click="(val)=>handleLocationNodeClick(val,index,formIndex)"> </el-tree>
									 </el-select>
									 <!-- 资产分类 -->
									<el-select v-if="form.type == 9 && form.field_name == 'cate_id'"   
										ref="treeSelect"
										 v-model="cate_name"
										placeholder="请选择" clearable 
										:disabled="form.field_name?false:true">
										<el-option value="0" label="无上级"></el-option>
										<el-tree node-key="id" :current-node-key="form.pid" :data="treeList" :props="treePorps" highlight-current ref="tree"
											@node-click="(val)=>handleNodeClick(val,index,formIndex)"> </el-tree>
									</el-select>
									<!-- 部门 -->
								<!-- 	<el-select v-if="form.type == 9 && form.field_name == 'dept_id'" 
										v-model="form.value"   
										placeholder="请选择"   
										@change="handleChangeSelect">
										<el-option v-for="(dept,deptIndex) in deptList"  :key="dept.id" :value="dept.id" :label="dept.name"></el-option>
									</el-select>  -->
									<el-select v-if="form.type == 9 && form.field_name == 'dept_id'" 
										v-model="form.dept_name"    placeholder="请选择" clearable 
										 @change="handleChangeSelect"> 
											<el-option value="0" label="无上级">无上级</el-option>
											<el-tree   node-key="id" :data="deptList" :props="treePorps"
											 highlight-current ref="tree" 
											 @node-click="(val)=>handleDeptNodeClick(val,index,formIndex)"> </el-tree>
									</el-select> 
									<!-- 部门成员 -->
									<!-- <el-select v-if="form.type == 9 &&form.field_name == 'user_id'" v-model="form.value"   placeholder="请选择">
										<template  v-for="dept in deptList">
											 <el-option
											 v-for="item2 in dept.children"
											 v-if="item2.department == deptId"
											:label="item2.label"
											:value="item2.id">
											</el-option>
										</template>			               
									</el-select> -->
									<el-select	filterable 
									v-if="form.type == 9 && form.field_name == 'user_id'" 
									v-model="form.value"   
									placeholder="请选择">
											 <el-option
											  v-for="admin in adminList"
											 :label="admin.name"
											 :value="admin.id">
											 </el-option> 
									</el-select>
									<!-- 下拉框 -->
									<el-select v-if="form.type == 9 
									&& form.field_name !== 'cate_id' 
									&& form.field_name !== 'location_id'
									&& form.field_name !== 'user_id'
									&& form.field_name !== 'dept_id'"
									 v-model="form.value" clearable placeholder="请选择"
									 :disabled="form.field_name?false:true">
										<el-option v-for="(selectItem,selectIndex) in form.content" :key="selectIndex" :value="selectIndex" :label="selectItem.name"></el-option>
									</el-select>
									<!-- 日期 -->
									<el-date-picker
										:disabled="form.field_name?false:true"
										v-if="form.type == 6"
										v-model="form.value"
										type="date"
										placeholder="选择日期">
									</el-date-picker>
								</el-form-item>
								
							 </el-col>
							<el-col :span="24" v-if="form.type == 1 || form.type == 4">		
								<el-form-item   :label="form.title" :prop="form.field_name">
									<!-- 多行文本 -->
									<el-input type="textarea" v-if="form.type == 1" rows="5"  v-model="form.value" placeholder=""  :disabled="form.field_name?false:true"></el-input>
									<!-- 图片 -->
									 <span style="color: #909399;" v-if="form.type == 4 && type == 1">支持上传3张照片</span>
									 <el-upload
										v-if="form.type == 4 && type == 1"
										class="avatar-uploader"
										:action="$uploadUrl"
										list-type="picture-card"
										 :limit="3"
										 :file-list="form.fileList"
										 :on-remove="handleRemove"
										:on-success="(val,file,fileList)=>handleSuccessUploadImgsGroup(val,file,fileList,index,formIndex)"
										:before-upload="handleBeforeUpload" >
										<img  v-if="form.value" :src="form.value" class="avatar">
										<i v-else class="el-icon-plus avatar-uploader-icon"></i>
									 </el-upload> 
									
									 
									<el-image v-if="form.type == 4 && type == 2 " v-for="img in form.value" :src="img"></el-image>
								</el-form-item>
							</el-col>
						</div>
					</el-row>
				</el-tab-pane>
			</el-tabs>
			</template>
		</el-form> 
		<div slot="footer" class="dialog-footer">
			<el-button @click="cancel()">取 消</el-button>
			<el-button type="primary" @click="confirm()">确 定</el-button>
		</div>
	</el-dialog>
</div>
</template>

<script>
export default {
	inject:['reload'],
    data() {
        return {
			active:0,
			isShow:false,
			batchStatus:true,
			executeStatus:true,			//true:执行盘点 false:取消盘点
			formModel:{},
			form:[],
			formStatus:true,
			cate_id: '',	//分类id
			cate_name:'',
			location_id:'',		//资产位置
			location_name:'',
			groupData:[],
			treeList: [], //分类目录
			locationTreeData:[],
			deptList:[],
			deptId:'',
			treePorps:{
				label:'name'
			},
			id:[],
			fileList:[],
			images:[],
			type:1,
			location_id:0,
			adminList:[],		//管理员列表
			userId:1
        }
    },
    created() {
		
    },
    mounted() {

    },
    methods:{
		getData(id,type=1){
			this.type = type
			if(!this.batchStatus){	//非批量 展示盘点资产详情和盘点记录
				this.id.push(id) 
				console.log(this.id)
				let url  = 'AssetDiscovery/details'
				if(this.type == 2){
					url = 'CseDiscovery/details'
				}
				this.$api.get(url,{id:id},res=>{
					if(res.code == 200){
						this.form =  res.data.group_data
						this.isShow =  true
					}
				})
				this.$api.get('AssetCategory/treeData',{
					type:this.type
				}, res => {
					if (res.code == 200) {
						this.treeList = res.data.tree_list
					}
				})
				this.$api.post('AssetLocation/treeData',{
					type:this.type
				}, res => {
					if (res.code == 200) {
						this.locationTreeData = res.data.tree_list
					}
				})
				//获取部门和人员数据
				// this.$api.get('admin/deptList',null,res=>{
				//     if(res.code == 200){
				// 		this.deptList =  res.data
				// 	}
				// })
				
				this.$api.get('Department/deptList',null,deptRes=>{
					if(deptRes.code == 200){
						this.deptList =  deptRes.data.list
						//管理员列表
						this.$api.get('admin/getDeptAdmin',null,res=>{
							 if(res.code == 200){
								this.adminList =  res.data.list
							 }else{
								 this.$message.error(res.msg)
							 }
						 })
					}
				})
				
			}else{
				this.id = id
				this.isShow =  true
			}
		},
		cancel(){
			this.id =  []
			this.isShow = false
		},
		handleChangeSelect(val){
			this.deptId  = val	
			
		},
		
		handleBeforeUpload(file){
		    // this.$emit('handleBeforeUpload',file)
			if(!this.$parent.$parent.$parent.$parent.$parent.handleBeforeUploadImg(file)){
				return false
			}
		},
		//批量盘点时上传的图片处理
		handleSuccessUploadImgs(res,file,fileList){
			 let  len  = fileList.length
			 if(len > 6){		
				 this.$message.error('图片附件，最多只能上传6张')
				  return false;
			 }
		     fileList[fileList.length-1]['img_url'] = res.data.url
			 if(this.images == undefined || this.images.length < 1){
				 this.images = []
			 }
			 this.images.push(res.data.url)
		},
		
		//单个盘点时上传的图片处理
		handleSuccessUploadImgsGroup(res,file,fileList,group_index,form_index){
			
			 fileList[fileList.length-1]['img_url'] = res.data.url
			
			if(!this.form[group_index]['data'][form_index]['value']){
				this.form[group_index]['data'][form_index]['value'] = []
			}
			this.form[group_index]['data'][form_index]['value'].push(res.data.url)
		},
		
		handleRemove(file){
			  let i=0
			  for(i;i<this.form.images.length;i++){
			      if(file.img_url == this.form.images[i]){
			          this.form.images.splice(i,1)
			      }
			  }
		},
		handleLocationNodeClick(data,index,formIndex){
			// this.location_name = data.name
			// this.location_id = data.id
			this.form[index]['data'][formIndex]['value'] = data.id 
			this.form[index]['data'][formIndex]['location_name']= data.name
		},
		
		handleDeptNodeClick(data,index,formIndex){
			// this.location_name = data.name
			// this.location_id = data.id
			this.form[index]['data'][formIndex]['value'] = data.id 
			this.form[index]['data'][formIndex]['dept_name']= data.name
			this.$nextTick(()=>{
				this.$api.get('admin/getDeptAdmin',{dept_id:data.id },res=>{
					 if(res.code == 200){
						this.adminList =  res.data.list
					 }else{
						 this.$message.error(res.msg)
					 }
				 })
			})
		},
		//执行盘点
		confirm(){
			var data = []
			if(this.batchStatus){	
				data = this.images
			}else{
				var group_data =  this.form
				group_data.map((item)=>{
					if(item.discovery_status){
						data =  item.data
					}
				})
			}
			
			let url = 'AssetDiscovery/start'
			if(this.type == 2){
				url = 'CseDiscovery/start' 
			}
			
			this.$api.post(url,{
				ids:this.id,
				data:data,
				batch_status:this.batchStatus?1:0
			},res=>{	
				if(res.code == 200){
					this.$message.success(res.msg)
					this.reload();
				}else{
					this.$message.error(res.msg)
				}
			})
		},
		
    },
}
</script>
<style scoped lang="scss">
	.asset-form{
		.el-select{
			width: 300px;
		}
		.el-input{
			width: 300px;
		}
	}
</style>