<template>
<div>
	<el-dialog title="添加盘点" :visible.sync='dialogAddVisible' :width="form.type==1?'40%':'30%'"  :close-on-click-modal="false" @close="close">
		<el-form   ref="form" :inline="form.type==1?true:false" :model="form" prop=""  label-width="100px" :rules="rules">
			<template v-if="form.type == 1">
				<el-steps :active="active" style="margin-bottom:50px;">
				  <el-step title="基础信息" description="本次盘点的资产范围">
				  </el-step>
				  <el-step title="分配任务" description="盘点员盘点的资产范围">
				  </el-step>
				  <el-step title="开始盘点" description="">
				  </el-step>
				</el-steps>
				<template v-if="active == 0">
					<el-form-item label="盘点单名称" prop="name" >
						<el-input  v-model="form.name" ></el-input>
					</el-form-item>
					<div class="discovy-title-box"><span class="title">盘点范围</span><span class="tips">(不选范围则为发起人权限的全部资产)</span></div>
					<el-form-item label="使用部门" >
					   <el-select  v-model="form.dept_id" multiple    placeholder="请选择" >
						 <el-option v-for="(dept,deptIndex) in deptList"  :key="dept.id" :value="dept.id" :label="dept.name"> </el-option>
					   </el-select> 
					</el-form-item>
					<el-form-item label="资产分类">
						   <el-select   ref="treeSelect"
							v-model="form.range_cate_name" placeholder="请选择" clearable 
							>
								<el-option value="0" label="无上级">无上级</el-option>
								<el-tree   node-key="id" :data="cateTreeData" :props="treePorps"
									show-checkbox
								 highlight-current ref="cateTree" 
								@check-change="handleCheckTree('cateTree')" > </el-tree>
						   </el-select>
					</el-form-item>
					 <el-form-item label="资产位置" prop="">
					   <el-select   ref="treeSelect"
						v-model="form.range_location_name" placeholder="请选择" clearable 
						>
							<el-option value="0" label="无上级">无上级</el-option>
							<el-tree   
								node-key="id" 
								:data="locationTreeData"
								 :props="treePorps"
								show-checkbox
								highlight-current ref="locationTree" 
								@check-change="handleCheckTree('locationTree')"
							> </el-tree>
					   </el-select>
					 </el-form-item>
					 
					 <div class="discovy-title-box"><span class="title">高级设置</span></div>
					 <el-form-item label="仅扫码盘点的分类" prop="">
						   <el-select   ref=""
							v-model="form.scan_code_cate_name" placeholder="请选择" clearable 
							>
								<el-option value="0" label="无上级">无上级</el-option>
								<el-tree   node-key="id" :data="cateTreeData" 
								:props="treePorps"
								show-checkbox
								 highlight-current ref="scanCodeTree" 
								 @check-change="handleCheckTree('scanCodeTree')"> </el-tree>
						   </el-select>
					 </el-form-item>
					 <el-form-item label="不支持批量盘点的分类" prop="">
						   <el-select   ref="treeSelect"
							v-model="form.not_batch_cate_name" placeholder="请选择" clearable 
							 >
								<el-option value="0" label="无上级">无上级</el-option>
								<el-tree   node-key="id" :data="cateTreeData" 
								:props="treePorps"
								show-checkbox
								 highlight-current ref="notBatchTree" 
								 @check-change="handleCheckTree('notBatchTree')"> </el-tree>
						   </el-select>
					 </el-form-item>
					<el-form-item label="" prop=""> 
						<el-checkbox v-model="form.picture_status">盘点图片在原资产照片后补充</el-checkbox>
						<p style="color: #909399;font-size: 12px;margin: 0px;">盘点图片在原资产照片后补充：勾选后，盘点时候的照片可以补充在资产信息中</p>
					</el-form-item>
				</template>  
				
				<template v-if="active == 1">
					<div style="margin-bottom:10px;">
						 <el-button size="small" type="" @click="handleTask(false)">分配任务</el-button>
						 <el-button size="small" type=""  @click="handleTask(true)">批量分配</el-button>
					 </div> 
					 <el-table
						:data="tableData" 
						border
					   >
						<el-table-column
							prop="name"
							label="姓名">
						</el-table-column>
						<el-table-column
							prop="cate_name"
							label="资产分类">
						</el-table-column> 
						<el-table-column
							prop=""
							label="资产位置">
							<template slot-scope="scope">
								<span v-if="scope.row.location_name == '-'">-</span>
								<span v-else v-for="item in scope.row.location_name">{{item}},</span>
							</template>
						</el-table-column> 
						<el-table-column
							label="操作">
							<template slot-scope="scope">
								<el-button  size="small" type="danger" plain   @click.native.prevent="deleteRow(scope.$index, tableData)">删除</el-button>
							</template>
						</el-table-column>
					 </el-table>
				</template>
				<template v-if="active == 2">
					<h3>{{createMsg}}</h3>
					<p style="margin-top:50px;">盘点名称：{{form.name}}</p>
					<p>盘点员：{{staffName}}</p>
				</template>
				<div>
					<el-button v-if="active > 0" style="margin-top: 12px;" @click="back">上一步</el-button>
					<el-button v-if="active < 2" style="margin-top: 12px;" @click="next">下一步</el-button>
				</div>	
			</template>
			<template v-if="form.type == 2">
				<el-form-item label="盘点单名称" prop="name" >
					<el-input  v-model="form.name" ></el-input>
				</el-form-item>
				<div class="discovy-title-box"><span class="title">盘点范围</span><span class="tips">(不选范围则为发起人权限的全部资产)</span></div>
				<el-form-item label="物料分类">
					   <el-select   ref="treeSelect"
						v-model="form.range_cate_name" placeholder="请选择" clearable 
						style="width: 100%;"
						>
							<el-option value="0" label="无上级">无上级</el-option>
							<el-tree   node-key="id" :data="cateTreeData" :props="treePorps"
								show-checkbox
							 highlight-current ref="cateTree" 
							@check-change="handleCheckTree('cateTree')" > </el-tree>
					   </el-select>
				</el-form-item>
				 <el-form-item label="物料仓库" prop="">
				   <el-select   ref="treeSelect"
					v-model="form.range_location_name" placeholder="请选择" clearable
					 style="width: 100%;"
					>
						<el-option value="0" label="无上级">无上级</el-option>
						<el-tree   
							node-key="id" 
							:data="locationTreeData"
							 :props="treePorps"
							show-checkbox
							highlight-current ref="locationTree" 
							@check-change="handleCheckTree('locationTree')"
						> </el-tree>
				   </el-select>
				 </el-form-item>
			</template>
		</el-form>
		<div v-if="form.type == 2" slot="footer" class="dialog-footer">
		    <el-button @click="dialogAddVisible = false">取 消</el-button>
		    <el-button type="primary" @click="confirm()">确 定</el-button>
		</div>
	</el-dialog>
	<AssignVue ref="AssignDialog" :assignData.sync="tableData"></AssignVue>
</div>
</template>

<script>
import AssignVue from './Assign.vue'
export default {
	inject:['reload'],
	components:{
	    AssignVue,
	},
    data() {
        return {
			active:0,
			dialogAddVisible:false,
			assignDrawer:false,
			cateTreeData:[],
			locationTreeData:[],
			adminList:[],
			deptList:[],
			treePorps:{
				label:'name'
			},
			tableData:[],
			batch_status:true,
			form:{
				id:'',
				name:'',
				check_location_id:'',
				check_picture:'',
				range_cate_id:[],
				range_cate_name:[],
				range_location_name:[],
				range_location_id:[],
				scan_code_cate_id:'',
				scan_code_cate_name:[],
				not_batch_cate_id:'',
				not_batch_cate_name:[],
				picture_status:0,
				type:1, 
				dept_id:0,
			},
			
			rules: {
				name: [{
					required: true,
					message: '请输入盘点名称',
					trigger: 'blur'
				}],
			},
			// assignData:[],
			createMsg:'',
			staffName:''
        }
    },
    created() {

    },
	watch: {
		'assignData'() {
			this.$nextTick(() => {
				console.log(this.tableData)
				// this.tableData =  this.assignData
			})
		},
		
	},
    mounted() {

    },
    methods:{
		
		//步骤条： 上一步
		back(){
			this.active--
			this.$nextTick(()=>{
				this.$refs.locationTree.setCheckedKeys(this.form.range_location_id)
				this.$refs.cateTree.setCheckedKeys(this.form.range_cate_id)
				this.$refs.scanCodeTree.setCheckedKeys(this.form.scan_code_cate_id)
				this.$refs.notBatchTree.setCheckedKeys(this.form.not_batch_cate_id)
			}) 
			
		},
		//步骤条： 下一步
		 next() {
			// if (this.active++ >= 2) this.active = 0;
			if(this.active == 0 && !this.form.name){
				this.$message.error('请输入盘点名称')
				return false
			}
			if(this.active  == 1 && this.tableData.length <1 ){
				this.$message.error('请分配盘点人员')
				return false
			}
			// this.active++
		
			if(this.active == 1){	//最后一步为提交
				this.confirm()
			}
			
			if(this.active < 1) this.active++
			// console.log( this.active)
		  },
		  close(){	//关闭弹窗
			  this.reload()
		  },
		  confirm(){
			  if(!this.form.name){
				  this.$message.error('请输入盘点名称')
				  return false
			  }
			  this.form.staff_data = this.tableData
			  var name =' '
			  let name_data  = this.form.staff_data.map((item)=>{
			  	return item.name
			  })
			  let url = 'AssetDiscovery/add'
			  if(this.form.type == 2){
				  url =  'CseDiscovery/add'
			  }
			  this.$api.post(url, this.form, res => {
			  	if (res.code == 200) {
			  		
			  		this.$message.success(res.msg)
			  		this.createMsg = "创建成功"
			  		this.staffName  = name_data.join(',')
			  		this.active++
					if(this.form.type == 2){
						this.dialogAddVisible = false
						this.reload();	
					}
			  	} else {
			  		this.createMsg = "创建失败"
			  		this.$message.error(res.msg)
			  	}
			  })
		  },
		  openForm(){
			  console.log('open')
		  },
		  
		  //盘点范围（位置/分类）
		  handleCheckTree(name='locationTree'){
			  var temp = []
			  var keys  = ''
			  var selected_name = ''
			  if(name == 'cateTree'){
				    keys  = 'range_cate_id'
					selected_name = 'range_cate_name'
			  }
			  if(name == 'locationTree'){
				    keys = 'range_location_id'
					selected_name = 'range_location_name'
			  }
			 if(name == 'scanCodeTree'){
				 keys =  'scan_code_cate_id'
				 selected_name= 'scan_code_cate_name'
			 }
			 
			 if(name == 'notBatchTree'){
				 keys =  'not_batch_cate_id'
				 selected_name= 'not_batch_cate_name'
			 }
			 console.log('name:',name)
			 
			  this.form[keys] = this.$refs[name].getCheckedKeys()
			  let nodes =  this.$refs[name].getCheckedNodes()
			  nodes.map((item)=>{
			  	 temp.push(item.name)
			  })
			  
			  this.form[selected_name]  = temp 
		  },
		handleAdd(){
			this.$api.get('AssetDiscovery/add',null,res=>{
				//获取资产位置和资产分类
				this.$api.get('AssetCategory/treeData',{
					type:this.form.type
				},res=>{
				    if(res.code == 200){
						this.cateTreeData =  res.data.tree_list
					}
				})
				this.$api.get('AssetLocation/treeData',{
					type:this.form.type
				},res=>{
				    if(res.code == 200){
						this.locationTreeData =  res.data.tree_list
					}
				})
				//获取部门和人员数据
				this.$api.get('admin/deptList',null,res=>{
				    if(res.code == 200){
						this.deptList =  res.data
					}
				})
				this.$api.post('admin/index',{
					page:0,
					limit:0
				},res=>{
				    if(res.code == 200){
						this.adminList =  res.data.data
					}
				})
				this.dialogAddVisible = true
			})
		},
		deleteRow(index, rows) {
			rows.splice(index, 1);
		},
		handleChangeSelect(val){
			this.adminList.forEach((item)=>{
				if(item.id == val){
					this.assignForm.name =  item.name
				}
			})
		},

		handleTask(batch_status){
			// this.batch_status  = batch_status
			// this.assignDrawer = true
			this.$refs.AssignDialog.batch_status= batch_status 
			this.$refs.AssignDialog.cateTreeData = this.cateTreeData
			this.$refs.AssignDialog.locationTreeData =  this.locationTreeData
			
			this.$refs.AssignDialog.adminList =  this.adminList
			this.$api.get('Department/deptList',null,res2=>{
				if(res2.code == 200){
					this.$refs.AssignDialog.deptList =  res2.data.list
					this.$refs.AssignDialog.assignDrawer = true
				}
			})
			
		},
	  //  handleCheckAllChange(val) {
		 //  this.checkedAdmin = val?this.adminList:[]
			// this.isIndeterminate = false;
		 //  },
	  //  handleCheckedAdminChange(value) {
			// var t_data = []
			// this.adminList.forEach((item)=>{
			// 	value.forEach((v)=>{
			// 		if(v == item.id){
			// 			t_data.push(item)
			// 		}
			// 	})
			// })
			// this.checkedData = t_data
			// let checkedCount = value.length;
			// this.checkAll = checkedCount === this.adminList.length;
			// this.isIndeterminate = checkedCount > 0 && checkedCount < this.adminList.length;
	  // },
	
    },
}
</script>
<style scoped lang="scss">
	.discovy-title-box{
		width: 100%;
		height: 40px;
		line-height: 40px;
		.title{
			font-size: 18px;
			font-weight: 600;
			color: #303133;
			border-bottom: 2px solid #409EFF;
			margin-right: 5px;
		}
		.tips{
			font-size: 12px;
			color: #909399;
		}
	}
</style>