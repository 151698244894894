<template>
	<div class="page-container">
		<el-dialog title="盘点员" :visible.sync='isShowStaff' width="60%" :close-on-click-modal="false">
			<el-table  :data="tableData" 
				:header-cell-style="{height:'45px',background: '#F2F6FC',padding: '0',fontSize:'12px'}" :row-style="{height:'30px'}"
				:cell-style="{padding: '0'}" style="width: 100%;">
				<el-table-column prop="name" label="姓名">
				</el-table-column>
				<el-table-column prop="role_name" label="角色">
				</el-table-column>
				<el-table-column prop="" label="应盘资产数">
					<template slot-scope="scope">
						<span>{{scope.row.discovery.storage_count}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="未盘">
					<template slot-scope="scope">
						<span>{{scope.row.discovery.un_discovery_count}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="已盘">
					<template slot-scope="scope">
						<span>{{scope.row.discovery.discovery_count}}</span>
					</template>
				</el-table-column>
			</el-table>
			<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
		</el-dialog>
	
	</div>
</template>
<script>
	export default {
		inject: ['reload'],
		data() {
			return {
				id:'',
				isShowStaff:false,
				tableData: [],
				currentPage: 1,
				pagesize: 10,
				total: 0,
				
			}
		},
		created() {
			
		},
		mounted() {

		},
		methods: {
			showStaff(id) { 
				this.id = id
				var arr = []
				this.$api.get('AssetDiscovery/staffIndex',{
					page:this.currentPage,
					limit:this.pagesize,
					id:id
				}, res => {
					if (res.code == 200) {
						this.tableData = res.data.list ?? []
						this.total = res.data.count
						this.isShowStaff =  true
					}
				})
			},

			getCurrPage(val) { //获取当前页
				this.currentPage = val
				this.showStaff(this.id)
			},
			getPageSize(val) { //获取每页条数
				this.pagesize = val
				this.showStaff(this.id)
			},

		}

	}
</script>
<style lang="scss">
	.bg-purple {
		padding: 10px;
		background: #d9ecff;
	}

	.bg-purple-light {
		background: #d9ecff;
	}
</style>
