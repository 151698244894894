<template>
	<div>
		<!-- 添加弹窗 -->
		<el-dialog title="添加资产" :visible.sync='dialogAddVisible' width="51%"  :close-on-click-modal="false">
			<!-- <div v-if="showStatus" class="masking"></div> -->
			<el-form class="asset-form" label-position="top"  v-if="formStatus"  ref="form"  :model="formModel" prop=""  style="height: 500px; overflow: auto;">
				<el-tabs v-model="active">
					<el-tab-pane v-for="(item,index) in formModel.form" :label="item.group_name" >
						<el-row> 	
							<div v-for="(form,formIndex) in item.data ">
								 <el-col :span="8"  v-if="form.type !==1  && form.type !== 4 ">
									 <el-form-item   :label="form.title" :required="form.required_status?true:false"> 
										<!-- 单行文本 -->
										 <el-input  v-if="form.type < 1 && form.field_name == 'asset_code'"   :disabled="asset_code_disabled"  :placeholder="asset_code_disabled?'已开启自动编码':'支持输入数字、字母、-'"  v-model="form.value"></el-input>
										 <el-input  v-if="form.type < 1 && form.field_name !== 'asset_code'"  :placeholder="form.title" v-model="form.value"></el-input>
										 <!-- 金额 -->
										  <el-input-number 
											v-if="form.type == 11"
											 v-model="form.value" 
											 :precision="2"
											  :min="0"  
											  :controls="false"
											  style="width:98%;"></el-input-number>
										 <!-- 所在位置 --> 
										 <el-select v-if="form.type == 9 && form.field_name == 'location_id'" ref="locationTreeSelect"  v-model="form.location_name" placeholder="请选择" clearable  @change="handleLocationSelect">
											<el-option value="0" label="无上级">无上级</el-option>
											<el-tree   node-key="id" :data="locationTreeData" :props="treePorps" highlight-current ref="tree" 
												@node-click="(val)=>handleLocationNodeClick(val,index,formIndex)"> </el-tree>
										 </el-select>
										 <!-- 资产分类 -->
										<el-select v-if="form.type == 9 && form.field_name == 'cate_id'"   ref="treeSelect" v-model="form.cate_name" placeholder="请选择" clearable  @change="handleSelect">
											<el-option value="0" label="无上级"></el-option>
											<el-tree node-key="id" :current-node-key="form.pid" :data="treeList" :props="treePorps" highlight-current ref="tree"
												@node-click="(val)=>handleNodeClick(val,index,formIndex)"> </el-tree>
										</el-select>
										<!-- 使用部门 -->
										<el-cascader
											 v-if="form.type == 9 && form.field_name == 'dept_id'"
											 style="width: 300px;"
											v-model="form.value"   
											 :props="props"   
											:options="deptList"  
											  @change="(val)=>handleChangeDept(val,index,formIndex)"
											  ref="cascader"  
											></el-cascader>   
										<!-- 使用人员 -->
											<el-select
												 v-if="form.type == 9 && form.field_name == 'user_id'"
												v-model="form.value" clearable placeholder="请选择" >
												<el-option v-for="admin in adminList"  :label="admin.name" :value="admin.id">
												</el-option>   
											</el-select> 
					
											
										<!-- <el-select v-if="form.type == 9  && form.field_name == 'dept_id'" v-model="form.dept_name"   placeholder="请选择" >
										  <el-option v-for="(dept,deptIndex) in deptList"  :key="dept.id" :value="dept.id" :label="dept.name"> </el-option>
										</el-select> -->
										<!-- 下拉框 -->
										<el-select v-if="form.type == 9 
											&& form.field_name !== 'cate_id' 
											&& form.field_name !== 'location_id' 
											&& form.field_name !== 'dept_id'
											&& form.field_name !== 'user_id'" v-model="form.value" clearable placeholder="请选择">
											<el-option v-for="(selectItem,selectIndex) in form.content" :key="selectIndex" :value="selectIndex" :label="selectItem.name"></el-option>
										</el-select>
										<!-- 日期 -->
										<el-date-picker
											v-if="form.type == 6"
											v-model="form.value"
											type="date"
											placeholder="选择日期">
										</el-date-picker>
									</el-form-item>
								 </el-col>
								<el-col :span="24" v-if="form.type == 1 || form.type == 4">		
									<el-form-item   :label="form.title" :required="form.required_status?true:false">
										<!-- 多行文本 -->
										<el-input type="textarea" v-if="form.type == 1" rows="5"  v-model="form.value" placeholder=""></el-input>
										<!-- 图片 -->
										<div v-if="form.type == 4">
											<span style="color: #909399;font-size: 12px;">最大上传数为6张</span>
											<el-upload
												class="avatar-uploader"
												:action="$uploadUrl"
												:multiple="true"
												list-type="picture-card"
												:limit='6'
												 :file-list="form.value"
												 :on-remove="(file,fileList)=>handleRemove(file,fileList,index,formIndex)"
												:on-success="(val,file,fileList)=>handleSuccessUploadImgs(val,file,fileList,index,formIndex)"
												:before-upload="handleBeforeUpload" >
												<!-- <template v-if="form.value">
													<img :style="form.value?{width:'145px',height:'145px'}:''" v-for="imgItem in form.value"  :src="imgItem" class="avatar">
												</template> -->
												<i  class="el-icon-plus avatar-uploader-icon"></i>
											</el-upload>
										</div>
										
									</el-form-item>
								</el-col>
							</div>
						</el-row>
					</el-tab-pane>
				</el-tabs>
			</el-form> 
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogAddVisible = false">取 消</el-button>
				<el-button type="primary" @click="confirm()">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		inject: ['reload'],
		data() {
			return {
				id:'',
				active:0,
				dialogAddVisible: false,
				asset_code_disabled:false,
				asset_code_placeholder:'',
				deptList:[],
				adminList:[],
				imageUrl:"",
				cardFieldData:[],		//卡片字段数据
				formModel:{
					form:[]
				},
				
				// form:[],
				formStatus:true,
				// cate_id: '',	//分类id
				// cate_name:'',
				// location_id:'',		//资产位置
				// location_name:'',
				groupData:[],
				treeList: [], //分类目录
				locationTreeData:[],
				discoveryId:0,		//盘点id
				discoveryStatus:0,		//盘盈时会改变
				rules: {},
				treePorps: {
					label: "name"
				},
				props:{
					value:'id',
					label:'name'
				},
				oldForm:[], 
				// showStatus:false,		//为true时加上蒙版 不可编辑
			}
		},
		created() {
			
		},
		mounted() {

		},
		watch: {
			formModel(){
				console.log('change')
			}, 
			// 'formModel.form'(){
			// 	this.oldForm =  JSON.parse(JSON.stringify(this.formModel.form))
			// 	// console.log(this.formModel.form)
			// }
			// 'groupData'(){
			// 	console.log('group')
			// }
		},
		methods: {
			// setRules(){
			// 	// console.log('set_rules')
			// 	let rule_data  = []
			// 	this.formModel.form.forEach(item=>{
			// 		item.data.forEach((fieldItem,fieldIndex)=>{
			// 			if(fieldItem.required_status){
						
			// 				 // this.rules[fieldItem['field_name']] = [{
			// 					// 	required: true,
			// 					// 	message: fieldItem.title+'不能为空',
			// 					// 	// trigger: 'blur'
			// 				 // }]
			// 				 this.rules[fieldItem['value']] = [{
			// 						required: true,
			// 						message: fieldItem.title+'不能为空',
			// 						// trigger: 'blur'
			// 				 }]
			// 			}
			// 		})
			// 	})
				
			// 	console.log( this.rules)
			// },
			handleSelect(val){
				this.cate_id =  val
			},
			handleLocationSelect(val){
				this.location_id = val
			},
			handleAdd(id, cateTreeData,locationTreeData,deptList) {
				this.id =  id
				this.treeList = cateTreeData
				this.locationTreeData = locationTreeData
				this.deptList =  deptList
				if (id < 1) {
					this.$api.get('AssetList/add', null, res => {
						if (res.code == 200) {
							if(res.data.asset_code_status == 1){	//自动编码 不支持手动输入
								this.asset_code_disabled =  true
								this.asset_code_placeholder = "已开启自动编码"
								
							}
							this.cardFieldData =  res.data.card_field_data
							// this.form  = res.data.group_data
							this.formModel.form = res.data.group_data
							this.dialogAddVisible = true
							 
							// this.setRules()
						}
					})
				} else {
					this.handleEdit(id)
				}
			},
			handleEdit(id) { //编辑数据
				this.$api.get('AssetList/edit', {
					'id': id
				}, res => {
					if (res.code == 200) {
						if(res.data.asset_code_status == 1){	//自动编码 不支持手动输入
							this.asset_code_disabled =  true
							this.asset_code_placeholder = "已开启自动编码"
						}
						this.cardFieldData =  res.data.card_field_data
						this.formModel.form  = res.data.group_data
						// this.form = res.data.info
						this.dialogAddVisible = true
						
						// this.$nextTick(()=>{
						// 	this.formModel.form[0]['data'][6]['fileList'] = [{url:'https://wework.qpic.cn/bizmail/UDS1cqEgB92Iic6wuxqgg91jibQibfVBUQibHxeHTkmr4mSERUicXYP4hfg/0'}];
						// 	console.log(this.formModel.form)
						// })
						
						// this.setRules()
						// this.$nextTick(function(){
						// 	// this.form.pname =  'xxxxxxxx' 
						// 	this.$refs.tree.setCurrentKey(res.data.info.pid)
						// })
					} else {  
						this.$message.error(res.msg)
					}
				})
			},
			handleChangeDept(value,index,formIndex){
				if(typeof value !== 'undefined'){
					// this.formModel.form[index]['data'][formIndex]['value'] = ''
					let dept_id = value[value.length-1]    
					 this.$api.get('admin/getDeptAdmin',{dept_id:dept_id},res=>{
						  if(res.code == 200){ 
								this.adminList =  res.data.list
						  }else{
							  this.$message.error(res.msg)
						  } 
					  })
					// this.formModel.form[index]['data'][formIndex]['value'] = dept_id
					// this.formModel.form[index]['data'][formIndex]['cate_name'] = data.name 
				} 
				
			},
			handleSuccessUploadImgs(res,file,fileList,group_index,form_index){
				 let  len  = fileList.length
				 if(len > 6){		//最多只能上传6张图
					 this.$message.error('图片附件，最多只能上传6张')
					  return false;
				 }
			     // fileList[fileList.length-1]['img_url'] = res.data.url
				// this.formModel.form[group_index]['data'][form_index]['fileList'][fileList.length-1]['url'] = res.data.url
				if(!this.formModel.form[group_index]['data'][form_index]['value']){
					this.formModel.form[group_index]['data'][form_index]['value'] = []
				}
				// this.formModel.form[group_index]['data'][form_index]['value'] =  this.formModel.form[group_index]['data'][form_index]['value'].concat({url:this.$imgDomain+res.data.url})
				 this.formModel.form[group_index]['data'][form_index]['value'].push({url:this.$imgDomain+res.data.url})
				 // console.log( this.formModel.form[group_index]['data'][form_index]['value']) 
				 console.log('list:',this.formModel.form[group_index]['data'][form_index]['value'])	
			},
			
			handleRemove(file,fileList,index,formIndex){
				 this.formModel.form[index]['data'][formIndex]['value'] =  fileList
			},
			
			handleBeforeUpload(file){
				if(!this.$parent.$parent.$parent.$parent.handleBeforeUploadImg(file)){
					return false
				}
			},  

			confirm() {
				let url = 'AssetList/add'
				if (this.id) {
					url = 'AssetList/edit'
				}
				this.formModel.form.discoveryStatus =  this.discoveryStatus
				this.$api.post(url, {
					id:this.id,
					group_data:this.formModel.form,
					discovery_id:this.discoveryId
				}, res => {
					if (res.code == 200) {
						this.dialogAddVisible = false
						this.$message.success(res.msg)
						this.reload();
						this.$refs["form"].resetFields();
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			
			//监听资产分类 切换表单字段
			handleNodeClick(data,index,formIndex){
				// this.cate_name = data.name
				// this.cate_id = data.id
				// this.formStatus =  false	
				this.$api.get('AssetList/groupData', {
					cate_id:data.id
				}, res => {
					if (res.code == 200) {
						// this.formStatus = false
						var oldForm = this.formModel.form		//更新分类前的表单
						// console.log('old-form:',oldForm)
						// console.log('group:',res.data.group_data)	
						//更新后 比对新老数据 并赋值
						
						if(res.data.group_data.length > 0){
							this.deptList =  this.deptList
							this.formModel.form =  res.data.group_data
							var formData = res.data.group_data
							formData.forEach((item,index)=>{
								item.data.forEach((childItem,childIndex)=>{
									oldForm.forEach(oldItem=>{
										oldItem.data.forEach((oldChildItem,oldChildIndex)=>{
											// if(childItem.id == oldChildItem.id){
											// 	if(typeof oldChildItem.value !== 'undefined'){
											// 		this.formModel.form[index]['data'][childIndex]['value'] = oldChildItem.value??''
											// 	}
											// }
											
											if(childItem.field_name == oldChildItem.field_name){
												if(typeof oldChildItem.value !== 'undefined'){
													this.formModel.form[index]['data'][childIndex]['value'] = oldChildItem.value??''
												}
												// console.log('oldChildItem.value:'+oldChildItem.value)
												if(childItem.field_name == 'cate_id'){
													// console.log(childItem)
													// console.log('cate_id');
													this.formModel.form[index]['data'][childIndex]['cate_name']  = data.name
													this.formModel.form[index]['data'][childIndex]['value'] = data.id
													// console.log(this.formModel.form[index]['data'])
													return false
												}
											}
										})
									})
								})
							})
							// this.formModel.form[index]['data'][formIndex]['value'] = data.id
							// this.formModel.form[index]['data'][formIndex]['cate_name'] = data.name
							this.formModel.form = JSON.parse(JSON.stringify(this.formModel.form))
							// this.formStatus = true
							// this.setRules()
						}else{
							// this.formStatus = true
						}
					} else {
						this.$message.error(res.msg)
					}
				})
				
			},
			handleLocationNodeClick(data,index,formIndex){
				// this.location_name = data.name
				// this.location_id = data.id
				// this.formStatus = false
				this.$nextTick(()=>{
					// this.formStatus  = true
					this.formModel.form[index]['data'][formIndex]['location_name'] = data.name
					this.formModel.form[index]['data'][formIndex]['value'] = data.id
					this.formModel.form  = JSON.parse(JSON.stringify(this.formModel.form))
				})
				
			},
		},
	}
</script>
<style scoped lang="scss">
	.asset-form{
		.el-select{
			width: 300px;
		}
		.el-input{
			width: 300px;
		}
		.masking{
			z-index: 999;
			width: 100%;
			height: 100%;
			background: transparent;
			position: absolute;
		}
	}
	
</style>
