<template>
	<div class="page-container">
		<p class="main-title">{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
		<div class="page-desc">
			<span v-if="search_key.type == 1">资产盘点是管理员对资产进行清查核算的创建入口，可以对系统内的资产统一进行盘点</span>
			<span v-if="search_key.type == 2">耗材盘点是仓库管理员周期查看、核对仓库内耗材的库存数据，进行对比更新的操作</span> 
		</div>
		<div class="search-form">
			<el-form ref="form" :inline="true" :model="search_key" prop="">
				<el-form-item label="">
					<el-input v-model="search_key.name" placeholder="输入关键字搜索"></el-input>
				</el-form-item>

				<el-button type="primary" @click="getData()">查询</el-button>
			</el-form>
		</div>
		<div style="margin-bottom:10px;">
			<template v-if="!batchStatus">
				<el-button size="small" type="" @click="handleAdd()">添加</el-button>
			</template>
			<template v-else> 
				<!-- <el-button size="small" type="" @click="handlePrint()">打印</el-button> -->
				<el-button size="small" type="" @click="handleDel()">删除</el-button>
			</template>
		</div>
			<!-- :row-key="getRowKeys" @selection-change="handleSelectionChange" -->
		<el-table 
			:data="tableData" 
		
			:header-cell-style="{height:'45px',padding: '0',fontSize:'12px'}" 
			:row-style="{height:'30px'}"
			:cell-style="{padding: '0'}" 
			style="width: 100%;">
			<el-table-column prop="" label="盘点名称">
				<template slot-scope="scope">
					<span style="color: #409EFF;"
						@click="details(scope.row.id,scope.row.status)">{{scope.row.name}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="status" label="盘点单状态">
				<template slot-scope="scope">
					<el-tag type="info" v-if="scope.row.status < 1" size="mini">盘点中</el-tag>
					<el-tag type="info" v-if="scope.row.status == 1" size="mini">盘点报告审批中</el-tag>
					<el-tag type="success" v-if="scope.row.status == 2" size="mini">已完结</el-tag>
					<el-tag type="warning" v-if="scope.row.status == 3" size="mini">已打回</el-tag>
					<el-tag type="danger" v-if="scope.row.status == 4" size="mini">已撤销</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="schedule" v-if="search_key.type == 1" label="资产盘点进度">
				<template slot-scope="scope">
					<span>{{scope.row.discovery_data_count}}/{{scope.row.storage_count}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="staff_schedule" v-if="search_key.type == 1" label="员工盘点进度">
			</el-table-column>
			<el-table-column prop="staff" label="盘点员">
				<template slot-scope="scope">
					<div v-if="search_key.type == 1" style="color: #409EFF;" v-for="staff in scope.row.staff" @click="staffDetails(scope.row.id)">{{staff.name}} </div>
					<div v-if="search_key.type == 2" >{{scope.row.staff[0].name}} </div>
				</template>
			</el-table-column>
			<el-table-column v-if="search_key.type==1" width="200px" prop="range_dept_name"  label="盘点范围（使用部门)">
				<template slot-scope="scope">
					<span>{{scope.row.range_dept_name?scope.row.range_dept_name:'-'}}</span>
				</template>
			</el-table-column>
			<el-table-column width="200px" prop="range_cate_name" :label="search_key.type==1?'盘点范围(资产分类)':'盘点范围（物料分类)'">
				<template slot-scope="scope">
					<span>{{scope.row.range_cate_name?scope.row.range_cate_name:'-'}}</span>
				</template>
			</el-table-column>
			<el-table-column width="200px" prop="range_location_name"  :label="search_key.type==1?'盘点范围(资产位置)':'盘点范围(物料仓库)'">
				<template slot-scope="scope">
					<span>{{scope.row.range_location_name?scope.row.range_location_name:'-'}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="create_admin_name" label="创建人">
			</el-table-column>
			<el-table-column prop="create_time" label="创建时间">
			</el-table-column>
			<el-table-column width="200px" label="操作">
				<template slot-scope="scope">
					<!-- <el-button  size="small" @click="handleEdit(scope.row.id)">修改</el-button> -->
					<el-button size="small" v-if="scope.row.status > 0 && search_key.type == 1" @click="handleApproval(scope.row.id)">审批信息 </el-button>
					<el-button size="small" @click="handleDel(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<AddVue ref="childDialog"></AddVue>
		<DetailVue ref="detailsDialog"></DetailVue>
		<StaffVue ref="staffDialog"></StaffVue>
		<ApprovalVue ref="approvalDialog"></ApprovalVue>
	</div>
</template>
<script>
	import AddVue from './Add.vue'
	import DetailVue from './Details.vue'
	import StaffVue from './Staff.vue'
	import ApprovalVue from './ApprovalDrawer.vue'
	export default {
		components: {
			AddVue,
			DetailVue,
			StaffVue,
			ApprovalVue
		},
		inject: ['reload'],
		data() {
			return {
				activeName: 'dist',
				batchStatus: false,
				search_key: {
					name: '',
					type:1
				},
				// cardName:'添加派发单据',
				// getRowKeys(row) {
				// 	return row.id
				// },
				tableData: [],
				currentPage: 1,
				pagesize: 10,
				total: 0,
			}
		},
		watch:{
			$route:{
				immediate:true,
				handler(to,from){
					// 1-资产盘点  2-耗材盘点
					this.search_key.type =  to.meta.type??1
					this.getData()
				}
		   } 	
		},
		created() {
			// this.getData()
		},
		mounted() {

		},
		methods: {
			getData() { //表格数据
				var arr = []
				let url =  'AssetDiscovery/index'
				if(this.search_key.type == 2){
					 url =  'CseDiscovery/index'
				}
				this.$api.post(url, this.search_key, res => {
					if (res.code == 200) {
						this.tableData = res.data.list ?? []
						this.total = res.data.count
					}
				})
			},

			//监听表格多选框
			handleSelectionChange(val) {
				this.multipleSelection = []
				var list = val;
				list.forEach(item => {
					this.multipleSelection.push(item.id)
				})
				if (this.multipleSelection.length > 0) {
					this.batchStatus = true
				} else {
					this.batchStatus = false
				}
				console.log('multi:', this.multipleSelection)
			},
			handleAdd() {
				this.$refs.childDialog.form.type = this.search_key.type
				this.$refs.childDialog.handleAdd()
			},
			//监听表格多选框
			handleSelectionChange(val) {
				this.multipleSelection = []
				var list = val;
				list.forEach(item => {
					this.multipleSelection.push(item.id)
				})
				console.log('multi:', this.multipleSelection)
			},


			//删除盘点
			handleDel(id) {
				this.$alert("确定删除该数据吗？", "删除提示", {
					showConfirmButton: true,
					showCancelButton: true,
					callback: action => {
						if (action == "confirm") {
							let url =  'AssetDiscovery/del'
							if(this.search_key.type == 2){
								 url =  'CseDiscovery/del'
							}
							this.$api.delete(url, {
								id: id
							}, res => {
								if (res.code == 200) {
									this.$message.success(res.msg);
									this.reload();
								}
							});
						} else if (action == "cancel") {
							this.reload();
						}
					}
				});
			},

			//查看盘点详情
			details(id, status) {
				this.$refs.detailsDialog.form.type = this.search_key.type
				this.$refs.detailsDialog.getDetails(id, status)
			},

			//盘点人员详情
			staffDetails(id) {
				this.$refs.staffDialog.showStaff(id)
			},
			
			//审批
			handleApproval(id){
				this.$refs.approvalDialog.getApproval(id)
			},

			getCurrPage(val) { //获取当前页
				this.currentPage = val
				this.getData()
			},
			getPageSize(val) { //获取每页条数
				this.pagesize = val
				this.getData()
			},
			// handleApproval(id) { //审批信息
			// 	this.$refs.approval.handleApproval(id)
			// },
		}

	}
</script>
<style lang="scss">
	.bg-purple {
		padding: 10px;
		background: #d9ecff;
	}

	.bg-purple-light {
		background: #d9ecff;
	}
</style>
