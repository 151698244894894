<template>
<div>
	<el-drawer  title="审批信息" :visible.sync="isShow" custom-class="demo-drawer" ref="drawer" size="20%">
		<div class="approval-box">
			<div class="appro-head">
				<span class="s-title">{{info.title}}</span>
				<div class="co-title" style="position: relative;">
					<div style="width: 30%;">{{info.co_name}}</div>
					<div>{{info.status_text}}</div>
					<div style="position: absolute;right: 30px;">
						<img v-if="info.status == 1" src="/images/asset/progress.png" alt="处理中"/>	
						<img v-if="info.status == 2 " src="/images/asset/closed.png" alt="已完结"/>
						<img v-if="info.status == 3 " src="/images/asset/reject2.png" alt="已驳回"/>
						<img v-if="info.status == 4 " src="/images/asset/withdraw.png" alt="已撤销"/>
					</div>
				</div>
			</div>
			
			<div class="range-box">
				<p style="font-size: 18px;font-weight: 600;color: black;">本次盘点范围/p>
				<div class="item">
					<span>公司部门</span>
					<span>{{info.range_dept_name?info.range_dept_name:'所有'}}</span>
				</div>
				<div class="item">
					<span>资产分类</span>
					<span>{{info.range_cate_name?info.range_cate_name:'所有'}}</span>
				</div>
				<div class="item">
					<span>资产位置</span>
					<span>{{info.range_location_name?info.range_location_name:'所有'}}</span>
				</div>
			</div>
			<div class="calc-box">
				<p style="font-size: 18px;font-weight: 600;color: black;">本次盘点汇总</p>
				<el-row style="padding: 5px;">
					<el-col :span="8">
						<div class="grid-content bg-purple-light">
							盘点状态
						</div>
					</el-col>
					<el-col :span="8">
						<div class="grid-content bg-purple-light">
							数量
						</div>
					</el-col>
					<el-col :span="8">
						<div class="grid-content bg-purple-light">
							占比
						</div>
					</el-col>
					<el-col :span="8" v-for="data in info.calc_data">
						<div class="grid-content ">
							{{data}}
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="process-box">
				<p style="font-size: 18px;font-weight: 600;color: black;">流程</p>
				<template  v-for="(approval,index) in info.approval">
					<div class="admin-info">
							<img style="width: 45px;height: 45px;border-radius: 5px;border: 1px solid #999;" :src="approval.avatar"/>
						<div style="width: 40%;">
							<p>{{approval.initiator_status?'发起人':'审批人'}}</p>
							<p style="font-size: 12px;color: #999;">{{approval.name}}</p>
							<p style="font-size: 12px;color: #999;" v-if="approval.remind_status == 1">{{approval.status<1?'（审批中）':(approval.status == 1?'（审批通过）':'（已拒绝）')}}</p>
							<p style="font-size: 12px;color: #999;">{{approval.remark}}</p>
						</div>
						<span style="font-size: 12px;color: #999;">{{approval.update_time}}</span>
					</div> 
					<div style="min-height: 50px;width: 2px;background-color: #c7c7c7;margin: 5px 0 5px 20px;" v-if="index < (info.approval.length-1)"></div>
				</template>
			</div>
		</div>
		<!-- <div  class="appro-footer">
			<el-button v-if="info.status == 0">催办</el-button>
			<el-button @click="revocation" v-if="info.status == 0">撤销</el-button>
			<el-button @click="agree">同意</el-button>
		</div> -->
		<div  class="appro-footer" style="display: flex;">
			<div style="margin-right: 20px;" v-if="info.status == 1 && info['create_admin_id'] == info['admin_id']">
				<el-button type="text"  @click="handleUrge">催办</el-button>
				<el-button type="text" @click="revocation" >撤销</el-button>
			</div> 
			<div v-if="approvalBtnStatus && info.status == 1" style="width: 65%;margin-left: 10px;">
				<el-button style="border-radius: 10px 0 0 10px;margin: 0;border: 1px solid #409EFF;color: #409EFF;" @click="showRemarkDialog(2)">驳回</el-button>
				<el-button style="border-radius: 0 10px 10px 0;margin: 0;" type="primary" @click="showRemarkDialog(1)">同意</el-button> 
			</div>
		</div>
		
		<el-dialog :title="agreeStatus==1?'同意审批':'驳回审批'" :visible.sync="dialogRemarkVisible" append-to-body="true">
		  <el-form :model="form">
		    <el-form-item label="输入审批意见" label-width="120px">
		     <el-input type="textarea"  rows="5"  v-model="form.remark" placeholder=""></el-input>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer" >
		    <el-button type="primary" @click="confirmAgree">{{agreeStatus==1?'确定同意':'确定驳回'}}</el-button>
		  </div>
		</el-dialog>
	</el-drawer>
	
</div>
</template>

<script>
export default {
	inject:['reload'],
    data() {
        return {
			dialogRemarkVisible:false,
			isShow:false,
			storageData:[],
			info:[],
			assetData:[],
			approvalBtnStatus:0,
			agreeStatus:0,
			form:{
				id:'',
				remark:''
			}
        }
    },
    created() {
		
    },
    mounted() {
		
    },
    methods:{
		getApproval(id){
			this.form.id = id
			this.$api.get('AssetDiscovery/getApproval', {id:id}, res => {
				if (res.code == 200) {
					this.info =  res.data.info	
					this.approvalBtnStatus =  res.data.approval_btn_status
					this.isShow =  true
				} 
			})	
		},
		revocation(){
			this.$alert("确定撤销该盘点吗？撤销后不可恢复", "撤销提示", {
			    showConfirmButton: true,
			    showCancelButton: true,
			    callback: action => {
			        if (action == "confirm") {
			            this.$api.put("AssetDiscovery/revocation", { "id": this.info.id }, res => {
			                if (res.code == 200) {
			                    this.$message.success(res.msg);
			                    this.reload();
			                }
			            });
			        }
			        else if (action == "cancel") {
			            this.reload();
			        }
			    }
			});
		},
		showRemarkDialog(type){
			this.agreeStatus = type
			this.dialogRemarkVisible = true
		},
		confirmAgree(){	//审批提交
			this.$api.post("AssetApproval/agree", { 
				id:this.form.id,
				type:3,
				remark:this.form.remark,
				status:this.agreeStatus,
				card_name:'资产盘点单'
			}, res => {
			    if (res.code == 200) {
			        this.$message.success(res.msg);
			        this.reload();
			    }else{
					this.$message.error(res.msg)
				}
			});
		},
		handleUrge(){	//催促审批
			console.log(this.form)
			this.$api.post("AssetApproval/urgeApproval", {
				id:this.form.id,
				type:3,
				card_name:'资产盘点单'
			}, res => {
			    if (res.code == 200) {
			        this.$message.success('已催办，待审批');
			        this.reload();
			    }else{
					this.$message.error(res.msg)
				}
			});
		}
    },
}
</script>
<style  lang="scss">
	.approval-box{
		width: 100%;
		height: 100%;
		background-color: #F2F6FC;
		padding: 10px;
		font-size: 14px;
		margin-bottom: 120px;
		.appro-head,.appro-info{
			background: white;
			margin-bottom: 10px;
			border-radius: 5px;
			text-indent: 20px;
		}
		.appro-head{
			.s-title{
				display: block;
				height: 40px;
				line-height: 40px;
				font-size: 16px;
				font-weight: 600;
			}
			.co-title{
				color: #909399;
				height: 30px;
				line-height: 30px;
				display: flex;
			}
		}
		.appro-info{
			span{
				display: block; 
				height: 30px;
				line-height: 30px; 
			}
			.s-title{
				color: #909399;
			}	
			.appro-asset{
				margin-top: 20px;
				p{
					width: 95%;
					margin: 0 auto;
					margin-top: 5px;
					height: 30px;
					line-height: 30px;
					background: #F2F6FC;
				}
			}
		}
		

		.range-box,.calc-box,.process-box{
			background: white;
			border-radius: 10px;
			text-indent: 20px;
			line-height: 30px;
		}
		.range-box{
			.item{
				display: flex;
				span{
					display: block;
				}
				:first-child{
					width: 25%;
				}
				:last-child{
					width: 65%;
					text-align: right;
				}
			}
			
		}
		.step-left__line{
			flex: 1;
			width: 0.05333rem;
			background-color: #c7c7c7;
			box-sizing: border-box;
			position: relative;
		}
		   
			
	}
	.appro-footer{
		width: 100%;
		background-color: white;
		height: 80px;
		line-height: 80px;
		position: fixed;
		bottom: 0;
		box-shadow: 0px 3px 3px #F2F6FC inset;
		padding-left: 10px;
	}
</style>
<style scoped lang="scss">
	.process-box{
		padding-left: 10px;
		padding-bottom: 20px;
		.admin-info{
			display: flex;
			align-items: center;
			p{
				margin: 0px;
			}
		}
	}
</style>