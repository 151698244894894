<template>
<div>
	<el-dialog :title="form.type==1?'盘点资产':'盘点耗材'" :visible.sync='dialogDetailsVisible' width="80%" :close-on-click-modal="false">
		<div style="margin-bottom:10px;display: flex;" >
			<div v-if="batchStatus">
				<template v-if="form.type == 1">
					<!-- 资产盘点 -->
					<el-button size="small" type="" @click="handleDiscovery()">执行盘点</el-button>
					<el-button size="small" type="" @click="handleCancel()">取消盘点</el-button> 
				</template>
				<template v-else>
					<!-- 耗材盘点 -->
					<el-button size="small" type="danger" @click="handleDel(multipleSelection)">删除盘盈</el-button> 
				</template>
					
			</div>
			<div style="margin-left: auto;">
				<el-button v-if="status<1"  size="small" type="primary" @click="handleAddAsset()">新增盘盈</el-button>
				<el-button v-if="status<1"  size="small" type="primary" @click="handleEnd()">{{form.type == 1?'结束盘点':'提交盘点结果'}}</el-button>
			</div>
		</div> 
		<el-table 
			row-key="asset_code"
			@selection-change="handleSelectionChange" 
			:header-cell-style="{height:'35px',background: '#F2F6FC',padding: '0',fontSize:'12px'}"
			:row-style="{height:'40px'}"
			 :cell-style="{padding: '0'}"
			 :data="tableData" style="width:100%">
			<el-table-column
			type="selection"
			:reserve-selection="true"
			width="55">
			</el-table-column>
			<template  v-for="item in theadData">
				<el-table-column :width="item.width" :key="item.field_name" :label="item.label">
					<template slot-scope="scope">
						<div v-if="item.type == 4  && scope.row[item.field_name] !== '-'">
							<el-image  style="width: 30px;height: 30px;" v-for="(imgItem,imgIndex) in scope.row[item.field_name]" :src="imgItem"></el-image>
						</div> 
						<!-- <span v-if="item.field_name == 'check_admin_id'">
							{{scope.row['admin_name']}}
						</span> -->
						<div v-if="item.field_name == 'discovery_status'">
								<el-tag type="warning" v-if="scope.row['discovery_status'] == 1">未盘</el-tag>
								<el-tag v-if="scope.row['discovery_status'] == 2">已盘</el-tag>
								<el-tag type="success" v-if="scope.row['discovery_status'] == 3">盘盈</el-tag>
								<el-tag type="danger" v-if="scope.row['discovery_status'] == 4">盘亏</el-tag>
						</div> 
						<!-- && item.field_name !== 'check_admin_id' -->
						<span v-if="item.type !== 4 
							
								&& item.field_name != 'discovery_status'">{{scope.row[item.field_name]}}</span>
					</template>
				</el-table-column>
			</template>
			<el-table-column fixed="right" width="400px" label="操作" v-if="discoveryStatus < 1">
				<template slot-scope="scope" >
					<el-button class="btn" size="mini" @click="handleDiscovery(scope.row.id,scope.row.discovery_status)">执行盘点</el-button>
					<el-button v-if="form.type == 1" class="btn" size="mini" @click="handleCancel(scope.row.id,scope.row.discovery_status)">取消盘点</el-button>
					<el-button class="btn" size="mini" type="danger" @click="handleDel(scope.row.id,scope.row.discovery_status)">删除盘盈</el-button>
					<el-button v-if="form.type == 1" size="mini"  type="warning" @click="handleLoss(scope.row.id,scope.row.discovery_status)">标记盘亏</el-button> 
				</template>
			</el-table-column>
		</el-table> 
		<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view>
	</el-dialog>
	
	<StartDiscoveryVue ref="discoveryDialog"></StartDiscoveryVue>
	<EndVue ref="EndDialog"></EndVue>
	<AddAssetVue ref="addAssetDialog"></AddAssetVue>
	<AddCseVue ref="addCseDialog"></AddCseVue>
</div>
</template>

<script>
import StartDiscoveryVue from './StartDiscovery.vue'
import EndVue from './EndPreview.vue'
import AddAssetVue from '../AssetList/Add.vue'
import AddCseVue from '../Common/ConsumableAdd.vue'
export default {
	inject:['reload'],
	components:{
	    StartDiscoveryVue,
		EndVue,
		AddAssetVue,
		AddCseVue
	},
    data() {
        return {
			batchStatus:false,
			dialogDetailsVisible:false,
			currentPage: 1,
			pagesize: 10,
			total: 0,
			tableData: [],
			theadData:[],
			form:{
				type:1
			},
			status:0,
			multipleSelectionData:[],
			cateTreeData:[],
			locationTreeData:[],
			discoveryStatus:0
        }
    },
    created() {
		
    },
    mounted() {

    },
    methods:{
		getDetails(id,status){
			this.status =  status
			this.form['page'] = this.currentPage-1
			this.form['limit'] = this.pagesize
			this.form['discovery_id'] = id
			
			let url =  'AssetDiscovery/assetList'
			if(this.form.type == 2){
				 url =  'CseDiscovery/stockList'
			}
			this.$api.post(url, this.form, res => {
				if (res.code == 200) {
					this.tableData = res.data.list ?? []
					this.total = res.data.count 
					this.theadData = res.data.thead
					this.discoveryStatus = res.data.discovery_status
					this.dialogDetailsVisible= true
				}
			})
		},
		
		//执行盘点
		handleDiscovery(id=0,status=''){
			if(id < 1 && this.multipleSelection){
				var i=0
				for(i=0;i<this.multipleSelectionData.length;i++){
					if(this.multipleSelectionData[i]['discovery_status'] !== 1){
						this.$message.error('只能执行【未盘】状态的资产')
						return false
					}
				}
				this.$api.post('AssetDiscovery/checkBatchDiscoveryStatus', {
					discovery_id:this.form['discovery_id'],
					asset_storage_id:this.multipleSelection
				}, res => {
					this.$refs.discoveryDialog.batchStatus  = true
					id =  this.multipleSelection
					
					this.$refs.discoveryDialog.id =  []
					this.$refs.discoveryDialog.getData(id, this.form.type) 
				},fail=>{
					this.$message.error(fail.msg)
					return false 
				}) 
			}else{
				if(status !== 1){
					this.$message.error('只能执行【未盘】状态的资产')
					return false
				}
				this.$refs.discoveryDialog.batchStatus  = false
				
				this.$refs.discoveryDialog.id =  []
				this.$refs.discoveryDialog.getData(id, this.form.type) 
			}
			// this.$refs.discoveryDialog.batchStatus  = false
			// this.$refs.discoveryDialog.id =  []
			// this.$refs.discoveryDialog.getData(id, this.form.type) 
		},
		
		//监听表格多选
		handleSelectionChange(val){
			this.multipleSelection  = []
			var list = val;
			 list.forEach(item=>{
			     this.multipleSelection.push(item.id )
			 })
			if(this.multipleSelection.length > 0){
				this.batchStatus = true
			}else{
				this.batchStatus = false
			}
			this.multipleSelectionData =  val
			
			
		},
		
		//删除盘盈数据
		handleDel(id,status){
			if (Array.isArray(id)) {
				//耗材 批量删除盘盈
				let i=0
				for(i;i<this.multipleSelectionData.length;i++){
					if(this.multipleSelectionData[i]['discovery_status'] != 3){
						this.$message.error('只能删除【盘盈】状态的资产')
						return false
					}
				}
			} else{
				if(status !== 3){
					this.$message.error('只能删除【盘盈】状态的资产')
					return false
				}
			}
		
			this.$alert("确定删除选中盘盈吗？", "删除提示", {
			    showConfirmButton: true,
			    showCancelButton: true,
			    callback: action => {
			        if (action == "confirm") {
						let url = 'AssetDiscovery/delAssetList'
						if(this.form.type == 2){
							url =  'CseDiscovery/delStockList' 
						}
			            this.$api.delete(url, {
							id:id,
							discovery_id:this.form['discovery_id']
						}, res => {
			                if (res.code == 200) {
			                    this.$message.success(res.msg);
			                    this.reload();
			                }
			            });
			        }
			        else if (action == "cancel") {
			            this.reload();
			        }
			    }
			});
		},
		//标记盘亏
		handleLoss(id){
			this.$alert("确定将当前资产作为盘亏处理？提交后不可恢复", "盘点提示", {
			    showConfirmButton: true,
			    showCancelButton: true,
			    callback: action => {
			        if (action == "confirm") {
						let url = 'AssetDiscovery/assetLoss'
			            this.$api.put(url, { 
							id:id ,
							discovery_id:this.form['discovery_id'] 
							}, res => {
			                if (res.code == 200) {
			                    this.$message.success(res.msg);
			                    this.reload();
			                }
			            });
			        }
			        else if (action == "cancel") {
			            this.reload();
			        }
			    }
			});
		},
		
		//取消盘点
		handleCancel(id=0,status_txt=''){
			if(id < 1 && this.multipleSelection){
				var i=0
				for(i=0;i<this.multipleSelectionData.length;i++){
					if(this.multipleSelectionData[i]['discovery_status'] !== 2){
						this.$message.error('只能取消【已盘】状态的资产')
						return false
					}
				}
				this.$refs.discoveryDialog.batchStatus  = true
				id =  this.multipleSelection
			}else{
				if(status_txt !== 2){
					this.$message.error('只能取消【已盘】状态的资产')
					return false
				}
			}
			
		
			
			this.$api.put('AssetDiscovery/cancel',{
				id:id>0?[id]:this.multipleSelection,
			},res=>{	
				if(res.code == 200){
					this.$message.success(res.msg)
					this.reload();
				}else{
					this.$message.error(res.msg)
				}
			})
		},
		//结束盘点
		handleEnd(){
			if(this.form.type == 1){	//资产-结束盘点
				// this.$refs.EndDialog.getCalcData(this.form['discovery_id'])
				this.$refs.EndDialog.getData(this.form['discovery_id'])
			}else{	//耗材-提交盘点
				this.$alert("耗材盘点提交后盘亏盘盈数据将同步更新不可撤回", "提交盘点提示", {
					showConfirmButton: true,
					showCancelButton: true,
					callback: action => {
						if (action == "confirm") {
							this.$api.put('CseDiscovery/end',{
								id:this.form['discovery_id']
							}, res => {
								if (res.code == 200) {
									this.$message.success(res.msg)
								}else{
									this.$message.error(res.msg)
								}
								this.reload()
							})
						}
						else if (action == "cancel") {
							this.reload();
						}
					}
				});
				
			}

		},
		
		//新增盘盈
		handleAddAsset(){
			if(this.form.type == 1){
				this.$api.get('AssetCategory/treeData',null, res => {
					if (res.code == 200) {
						this.cateTreeData = res.data.tree_list
						this.$api.post('AssetLocation/treeData',null, res => {
							if (res.code == 200) {
								this.locationTreeData = res.data.tree_list
								this.$refs.addAssetDialog.discoveryId = this.form['discovery_id']
								this.$refs.addAssetDialog.discoveryStatus =  1
								this.$refs.addAssetDialog.handleAdd(0, this.cateTreeData, this.locationTreeData)
							}
						})
					}
				})
			}else{
				// var mtl_code_data = this.tableData.map(item=>{ return item.mtl_code})
				this.$refs.addCseDialog.cardName = '耗材入库单'
				this.$refs.addCseDialog.addUrl  = '/CseStorage/add'
				this.$refs.addCseDialog.storageType = 1
				this.$refs.addCseDialog.discoveryId = this.form['discovery_id']
				this.$refs.addCseDialog.mtlCodeData = this.tableData.map(item=>{ return item.mtl_code})
				this.$refs.addCseDialog.handleAdd()
			}
			
		},
		getCurrPage(val) { //获取当前页
				this.currentPage = val
				this.getDetails(this.form['discovery_id'],this.status)
			},
		getPageSize(val) { //获取每页条数
			this.pagesize = val
			this.getDetails(this.form['discovery_id'],this.status)
		},
    },
}
</script>
<style scoped>

</style>